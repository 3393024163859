import type { FunctionComponent } from "react"

import classnames from "classnames"
import dynamic from "next/dynamic"

import { hasCustomTheme } from "@/modules/careerCenter/hasCustomTheme"
import { useCareerCenterFetch } from "@/modules/careerCenter/hooks/useCareerCenterFetch"
import { LOGO_FY_2025 } from "@/modules/unleash/flags"
import { useFlag } from "@/modules/unleash/hooks/useFlag"

import styles from "./Brand.module.css"

export type BrandProps = {
  from: "footer" | "header"
  JTLogoClassName?: string
}

const CCLogoDynamic = dynamic(() => import("./CCLogo").then(mod => mod.CCLogo))
const JTLogoDynamic = dynamic(() => import("./JTLogo").then(mod => mod.JTLogo))

export const Brand: FunctionComponent<BrandProps> = ({ from, JTLogoClassName }) => {
  const { dataCareerCenter } = useCareerCenterFetch()
  const hasCustomSchoolTheme = hasCustomTheme(dataCareerCenter?.careerCenterConf?.theme)

  const isLogoFiscalYear25Enabled = useFlag(LOGO_FY_2025)

  return (
    <div className={classnames(styles["app-Brand"], styles["app-Brand--header"])}>
      {hasCustomSchoolTheme && dataCareerCenter?.logo ? (
        <CCLogoDynamic
          name={dataCareerCenter?.careerCenterConf?.careerCenterNameLocalized || "Career Center"}
          logo={dataCareerCenter.logo}
          secondaryLogo={dataCareerCenter?.secondaryLogo}
          isLogoFiscalYear25Enabled={isLogoFiscalYear25Enabled}
          from={from}
        />
      ) : (
        <JTLogoDynamic
          from={from}
          isLogoFiscalYear25Enabled={isLogoFiscalYear25Enabled}
          JTLogoClassName={JTLogoClassName}
        />
      )}
    </div>
  )
}
