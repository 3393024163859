import type { CareerCenter, CareerCenterResponse } from "@/modules/careerCenter/types"
import type { NextRequest } from "@/modules/fetch/types"
import type { I18nLocale } from "@/modules/i18n/types"

import { getResultFromResponse } from "@/modules/apiRequests/handlers"
import { handleSchemaValidation } from "@/modules/apiRequests/schemaValidation"
import { careerCenterSchema } from "@/modules/careerCenter/schemas"
import { handleFetchError } from "@/modules/fetch/errorHandler"
import { fetcher } from "@/modules/fetch/fetcher"
import { DEFAULT_STORAGE_DURATION } from "@/modules/serverStorage/constants"

import { CAREER_CENTER_QUERY_KEY } from "./constants"

type GetCareerCenterParams = {
  locale: I18nLocale
  permalink: string
  req?: NextRequest
}

const CAREER_CENTER_ENDPOINT = "/v1alpha1/context/career-center"

export const getCareerCenter = async ({ locale, permalink, req }: GetCareerCenterParams): Promise<CareerCenter> => {
  if (!permalink) {
    throw new Error(`Missing permalink when fetching career center.`)
  }

  return fetcher(`${CAREER_CENTER_ENDPOINT}/${permalink}?locale=${locale}`, {
    nextRequest: req,
    serviceDomain: "KRAKEND_API",
    storageDuration: DEFAULT_STORAGE_DURATION,
    storageKey: `${CAREER_CENTER_QUERY_KEY}::${permalink}::${locale}`,
    withUserSession: false,
  })
    .then(getResultFromResponse<CareerCenterResponse>())
    .then(({ careerCenter }) => careerCenter)
    .then(data => handleSchemaValidation(data, careerCenterSchema, CAREER_CENTER_ENDPOINT))
    .catch(handleFetchError(CAREER_CENTER_ENDPOINT))
}
