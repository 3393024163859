import type { PageTranslations } from "@/modules/layouts/contexts/foLayout"
import type { FunctionComponent } from "react"

import classNames from "classnames"
import dynamic from "next/dynamic"
import { usePathname } from "next/navigation"

import { hasCustomTheme } from "@/modules/careerCenter/hasCustomTheme"
import { useCareerCenterFetch } from "@/modules/careerCenter/hooks/useCareerCenterFetch"
import { Brand } from "@/modules/headerFooterFO/Brand"
import { FooterLegals } from "@/modules/headerFooterFO/Footer/Legals/FooterLegals"
import { isChildPage, isCorporatePage } from "@/modules/headerFooterFO/utils/isPage"
import { PUBLIC_LOCALES } from "@/modules/locales/constants"
import { useLocale } from "@/modules/locales/useLocale"
import { useCurrentUserFetch } from "@/modules/user/client/hooks/useCurrentUserFetch"

import styles from "./Footer.module.css"

export type FooterProps = {
  pageTranslations: PageTranslations
}

const DynamicFooterMenu = dynamic(() =>
  import("@/modules/headerFooterFO/Footer/Menu/FooterMenu").then(mod => mod.FooterMenu)
)

const DynamicLocaleSelector = dynamic(() =>
  import("@/modules/locales/LocaleSelector/LocaleSelector").then(mod => mod.LocaleSelector)
)

const DynamicLocalesDropdown = dynamic(() =>
  import("@/modules/locales/LocalesDropdown/LocalesDropdown").then(mod => mod.LocalesDropdown)
)

const DynamicSocialsList = dynamic(() =>
  import("@/modules/socials/SocialsList/SocialsList").then(mod => mod.SocialsList)
)

export const Footer: FunctionComponent<FooterProps> = ({ pageTranslations }) => {
  const pathname = usePathname()
  const { dataCurrentUser } = useCurrentUserFetch()
  const { dataCareerCenter } = useCareerCenterFetch()
  const locale = useLocale()

  const hasCustomSchoolTheme = hasCustomTheme(dataCareerCenter?.careerCenterConf?.theme)
  const displayLocales = !dataCurrentUser?.isLogged && (!isChildPage(pathname) || isCorporatePage(pathname))
  const withMenu = !hasCustomSchoolTheme && PUBLIC_LOCALES.includes(locale)

  const classnames = classNames({
    [styles.logo__smallScreenBottomMargin]: !displayLocales,
  })
  return (
    <footer className={styles.main}>
      <div className={styles.container}>
        <div className={styles.brand}>
          <Brand from="footer" JTLogoClassName={classnames} />
          <div className={styles.brandContainer}>
            {hasCustomSchoolTheme ? (
              <DynamicLocaleSelector />
            ) : (
              <>
                {displayLocales && (
                  <div className={styles.locales}>
                    <DynamicLocalesDropdown pageTranslations={pageTranslations} position="bottom" />
                  </div>
                )}

                <DynamicSocialsList />
              </>
            )}
          </div>
        </div>

        {withMenu && <DynamicFooterMenu />}

        <FooterLegals />
      </div>
    </footer>
  )
}
