import * as v from "valibot"

import { i18nLocaleSchema } from "@/modules/i18n/schemas"

// https://github.com/fabian-hiller/valibot/discussions/620#discussioncomment-9641537
const colorValueHexSchema = v.custom<`#${string}`>(input => typeof input === "string" && input.startsWith("#"))

export const CCThemeColorsSchema = v.object({
  boxTitleColor: colorValueHexSchema,
  buttonTextColor: colorValueHexSchema,
  headerColor: colorValueHexSchema,
  headerTextColor: colorValueHexSchema,
  mainColor: colorValueHexSchema,
  mainDarkenColor: colorValueHexSchema,
  mainTextColor: colorValueHexSchema,
})

const emptyCCThemeColorsSchema = v.object({
  boxTitleColor: v.literal(""),
  buttonTextColor: v.literal(""),
  headerColor: v.literal(""),
  headerTextColor: v.literal(""),
  mainColor: v.literal(""),
  mainDarkenColor: v.literal(""),
  mainTextColor: v.literal(""),
})

export const careerCenterModulesSchema = v.object({
  hasAdvices: v.optional(v.boolean()),
  hasAppointments: v.optional(v.boolean()),
  hasAppointmentsLaunchedInFo: v.optional(v.boolean()),
  hasArticles: v.optional(v.boolean()),
  hasEnrolment: v.optional(v.boolean()),
  hasEventHighlightPartners: v.optional(v.boolean()),
  hasEvents: v.optional(v.boolean()),
  hasGenericPositions: v.optional(v.boolean()),
  /** @deprecated Use hasEventHighlightPartners or hasJobadHighlightPartners instead. */
  hasHighlightPartners: v.optional(v.boolean()),
  hasJobOffers: v.optional(v.boolean()),
  hasJobadHighlightPartners: v.optional(v.boolean()),
  hasLearningPlatformAccess: v.optional(v.boolean()),
  hasRssFeed: v.optional(v.boolean()),
})

export const careerCenterThemeSchema = v.union([CCThemeColorsSchema, emptyCCThemeColorsSchema])

export const careerCenterConfSchema = v.object({
  careerCenterNameLocalized: v.optional(v.string()),
  customResourceLocale: v.optional(v.nullable(v.string())),
  hasPartners: v.boolean(),
  locales: v.array(i18nLocaleSchema),
  modules: careerCenterModulesSchema,
  positionCategoryIds: v.array(v.string()),
  postOfferFromFrontOffice: v.boolean(),
  theme: careerCenterThemeSchema,
  timeZoneIdentifier: v.optional(v.string()),
})

const mainCareerCenterConfSchema = v.object({
  hasMainCareerCenterArticles: v.optional(v.boolean()),
  mainCareerCenterCustomResourceLocale: v.optional(v.nullable(v.string())),
})

const associatedCareerCenterSchema = v.object({
  id: v.string(),
  name: v.string(),
})

export const careerCenterSchema = v.object({
  associatedCareerCenters: v.array(associatedCareerCenterSchema),
  careerCenterConf: v.nullable(careerCenterConfSchema),
  id: v.string(),
  /** @deprecated Use the "id" property instead. */
  idOld: v.number(),
  intranet: v.boolean(),
  logo: v.string(),
  mainCareerCenterConf: v.optional(v.nullable(mainCareerCenterConfSchema)),
  mainCareerCenterId: v.optional(v.string()),
  mainCareerCenterIdOld: v.optional(v.number()),
  name: v.string(),
  secondaryLogo: v.optional(v.string()),
  shortName: v.string(),
})
