import { useContext } from "react"

import { ServerContext } from "@/modules/server/context"

export function useFlag(flagName: string, variantName?: string): boolean {
  const context = useContext(ServerContext)

  if (!context) {
    return false
  }

  const { activeFlags } = context

  return !!activeFlags.find(({ name, variant }) => {
    if (variant && variantName) {
      return name === flagName && variant === variantName
    }

    return name === flagName
  })
}
