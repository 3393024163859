import type { CareerCenter } from "@/modules/careerCenter/types"

import { useQuery } from "@tanstack/react-query"
import { useContext } from "react"

import { QUERY_CACHE_TIME, QUERY_STALE_TIME } from "@/modules/careerCenter/services/constants"
import { getCareerCenter } from "@/modules/careerCenter/services/getCareerCenter"
import { getCareerCenterQueryKey } from "@/modules/careerCenter/services/getQueryKey"
import { useLocale } from "@/modules/locales/useLocale"
import { useTrackJs } from "@/modules/monitoring/TrackJs/useTrackJs"
import { ServerContext } from "@/modules/server/context"

export type CareerCenterFetch = {
  dataCareerCenter?: CareerCenter
  isLoadingCareerCenter: boolean
  isSuccessCareerCenter: boolean
  isErrorCareerCenter: boolean
}

export const useCareerCenterFetch = (): CareerCenterFetch => {
  const trackJs = useTrackJs()
  const context = useContext(ServerContext) || { hostname: "" }

  if (!context || !context?.hostname) {
    throw new Error(`Missing hostname when retrieving career center data.`)
  }

  const { hostname } = context
  const [permalink] = hostname.split(".")
  const locale = useLocale()

  const {
    data: dataCareerCenter,
    isPending: isLoadingCareerCenter,
    isSuccess: isSuccessCareerCenter,
    isError: isErrorCareerCenter,
    error,
  } = useQuery({
    gcTime: QUERY_CACHE_TIME,
    queryFn: () =>
      getCareerCenter({
        locale,
        permalink,
      }),
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getCareerCenterQueryKey({ permalink }),
    staleTime: QUERY_STALE_TIME,
  })

  if (error) {
    trackJs?.track(`⚠️ useCareerCenterAutocomplete error ${permalink}, ${JSON.stringify(error)}`)
  }

  return {
    dataCareerCenter,
    isErrorCareerCenter,
    isLoadingCareerCenter,
    isSuccessCareerCenter,
  }
}
